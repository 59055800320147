<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="ml-8">
        <div class="row">
          <p class="table-header">ข้อมูลโครงงาน</p>
        </div>

        <!-- Project list (Table) -->
        <table>
          <!-- Table header -->
          <thead>
            <tr>
              <th class="w-1/12">ลำดับ</th>
              <th class="w-4/12">หัวข้อโครงงาน</th>
              <th class="w-2/12">นักศึกษาผู้รับผิดชอบ</th>
              <th class="w-2/12">อาจารย์ที่ปรึกษา</th>
              <th class="w-3/12">อาจารย์ที่ปรึกษาร่วม</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Horizontal line -->
            <tr><td colspan="7"><hr></td></tr>
            <!-- Data -->
            <tr v-for="(project, index) in projects" :key="project.id">
              <td>{{ index + 1 }}</td>
              <td class="text-left">{{ project.topic_th }}<br>({{ project.topic_en }})</td>
              <td class="whitespace-pre ...">
                <div v-for="member in project.member_details" :key="member.id">{{ member.name }}</div>
              </td>
              <td v-for="advisor in project.advisor_details" :key="advisor.id" class="whitespace-pre ...">
                <div v-if="project.advisor_details.length === 1">
                  <p v-if="advisor.role === 'A'">{{ advisor.name }}</p>
                </div>
                <div v-else>
                  <p v-if="advisor.role === 'A'">{{ advisor.name }}</p>
                  <p v-if="advisor.role === 'C'">{{ advisor.name }}</p>
                </div>
              </td>
              <td v-if="project.advisor_details.length === 1">-</td>
              <td>
                <router-link :to="'/project/' + project.id + '/'">
                  <button class="view-button mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="white">
                      <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"

export default {
  name: "ProjectList",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      projects: null,
      role: null
    }
  },
  async created() {
    document.title = "ข้อมูลโครงงาน | Carbon"
    this.role = this.$store.getters.payload.role
    if (this.role !== "STD") {
      this.$router.push("/")
    }
    this.projects = await this.$store.dispatch("getProjectByUserId", this.$store.getters.payload.user_id)
    this.loading = false
  },
}
</script>
